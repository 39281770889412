module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2IocGGc1v0lCtrvRYVdVO1RxFMq","trackPage":true,"trackPageDelay":50,"delayLoad":true,"delayLoadTime":4000,"manualLoad":false,"loadType":"default"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
